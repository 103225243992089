import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    // CHRISTMAS

    const wireContainer = document.querySelector('.wire-container');
    if (!wireContainer)
      return
    const christmasContainer = document.querySelector('#christmas');
    const path = document.querySelector('#christmas-wire');
    const bulbCount = 14; // Number of bulbs

    // New Colors for bulbs
    const colors = ['#FF6B6B', '#FFD93D', '#6BCB77', '#4D96FF',];

    // Helper function to get point on path
    function getPointAtProgress(progress) {
      const length = path.getTotalLength();
      const point = path.getPointAtLength(progress * length);
      return {x: point.x, y: point.y};
    }

    // Function to generate a random color that isn't the same as the last used color
    function getUniqueColor(lastColor) {
      let color;
      do {
        color = colors[Math.floor(Math.random() * colors.length)];
      } while (color === lastColor); // Ensure the new color is different from the previous one
      return color;
    }

    // Function to toggle bulb state with randomized delay
    function toggleBulb(bulbBody, lastColor) {
      const delayOn = Math.random() * 3000 + 2000; // Random on-time (2s to 5s)
      const delayOff = Math.random() * 3000 + 2000; // Random off-time (2s to 5s)

      setTimeout(() => {
        // Get a new color that isn't the same as the last used color
        const newColor = getUniqueColor(lastColor);
        bulbBody.style.setProperty('--color', newColor);
        bulbBody.style.opacity = '1';
        bulbBody.style.boxShadow = `0 0 20px 10px ${newColor}`;

        setTimeout(() => {
          // Turn off
          bulbBody.style.opacity = '0.4'; // Reduced opacity when off
          bulbBody.style.boxShadow = `0 0 0px 0px ${newColor}`;

          // Recursive call for continuous toggling
          toggleBulb(bulbBody, newColor);
        }, delayOff);
      }, delayOn);
    }

    // Create bulbs dynamically
    let lastColor = null; // To store the color of the previous bulb

    for (let i = 0; i < bulbCount; i++) {
      const bulb = document.createElement('div');
      bulb.className = 'bulb';

      // Bulb body and base
      const bulbBody = document.createElement('div');
      bulbBody.className = 'bulb-body';
      const initialColor = getUniqueColor(lastColor); // Ensure no two adjacent bulbs have the same color
      bulbBody.style.setProperty('--color', initialColor);

      lastColor = initialColor; // Update last color to the current one

      const bulbBase = document.createElement('div');
      bulbBase.className = 'bulb-base';

      bulb.appendChild(bulbBody);
      bulb.appendChild(bulbBase);

      // Calculate position on the wire
      const progress = i / (bulbCount - 1); // Progress between 0 and 1
      const {x, y} = getPointAtProgress(progress);

      // Position the bulb
      bulb.style.left = `${x}px`;
      bulb.style.top = `${y}px`;

      // Adjust the wire to attach to the base
      bulbBase.style.top = `calc(70% - 1px)`; // Align base directly over the wire path

      // Append bulb to the container
      wireContainer.appendChild(bulb);

      // Randomly set initial state of the bulb (on or off)
      if (Math.random() > 0.5) {
        bulbBody.style.opacity = '1';
        bulbBody.style.boxShadow = `0 0 20px 10px ${initialColor}`;
      }

      // Start the toggling with randomized delay
      toggleBulb(bulbBody, initialColor);
    }

    // Snowflake variables
    const maxSnowflakes = 80; // Max number of snowflakes
    let activeSnowflakes = 0; // Track number of active snowflakes

    // Function to create snowflakes
    function createSnowflakes(numSnowflakes) {
      if (activeSnowflakes >= maxSnowflakes) return; // Prevent creating more than max snowflakes

      for (let i = 0; i < numSnowflakes; i++) {
        if (activeSnowflakes >= maxSnowflakes) return; // Stop creating new snowflakes if the max limit is reached

        const snowflake = document.createElement('div');
        snowflake.className = 'snowflake';

        // Randomize size, speed, and opacity of snowflakes
        const size = Math.random() * 5 + 3; // Snowflake size between 3px and 8px
        snowflake.style.width = `${size}px`;
        snowflake.style.height = `${size}px`;

        // Set random position
        snowflake.style.left = `${Math.random() * window.innerWidth}px`;

        // Set random opacity
        snowflake.style.opacity = Math.random() * 0.7 + 0.3; // Random opacity between 0.3 and 1

        // Set random fall speed and drift
        const fallDuration = Math.random() * 15 + 3; // Slow down the fall (30s to 60s)
        snowflake.style.animationDuration = `${fallDuration}s`;

        // Add snowflake to the container
        christmasContainer.appendChild(snowflake);

        snowflake.animate(
          [
            { transform: `translateY(0) translateX(${Math.random() * 10}px)` },
            { transform: `translateY(20px) translateX(${Math.random() * 10}px)`  },
            { transform: `translateY(40px) translateX(${Math.random() * 10}px)` },
            { transform: `translateY(60px) translateX(${Math.random() * 10}px)` },
            { transform: `translateY(80px) translateX(${Math.random() * 10}px)` },
            { transform: `translateY(100px) translateX(${Math.random() * 10}px)` },
            { transform: `translateY(120px) translateX(${Math.random() * 10}px)` },
            { transform: `translateY(140px) translateX(${Math.random() * 10}px)` },
            { transform: `translateY(160px) translateX(${Math.random() * 10}px)` },
            { transform: `translateY(180px) translateX(${Math.random() * 10}px)` },
            { transform: `translateY(200px) translateX(${Math.random() * 10}px)` },
          ], {
            // timing options
            duration: fallDuration * 1000,
            iterations: Infinity
          }
        )

        setInterval(() => {
          snowflake.remove()
          activeSnowflakes--;
        }, fallDuration * 1000);

        activeSnowflakes++;
      }
    }

    // Generate snowflakes continuously
    setInterval(() => {
      createSnowflakes(2); // Create 2 new snowflakes per interval
    }, 500);
  }

  connect() {
  }
}
