import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["entry", "creditTotal", "debitTotal", "soldeTotal"]

  connect() {
    this.total_being_edited = false;
    this.updateQueue = Promise.resolve();
  }

  entryTargetConnected(elem) {
    const waitForEditingToComplete = () => {
      if (this.total_being_edited) {
        setTimeout(waitForEditingToComplete, 500);
      } else {
        this.updateTotalwithvalues(elem);
      }
    };

    waitForEditingToComplete();
  }

  updateTotalwithvalues(elem) {
    this.updateQueue = this.updateQueue.then(() => {
      this.total_being_edited = true;
      let credit = parseFloat(elem.querySelector(".entry-credit").textContent.replace(/ /g,''));
      let debit = parseFloat(elem.querySelector(".entry-debit").textContent.replace(/ /g,''));

      let credit_total = parseFloat(this.creditTotalTarget.textContent.replace(/ /g,''));
      let debit_total = parseFloat(this.debitTotalTarget.textContent.replace(/ /g,''));
      credit_total += credit;
      debit_total += debit;
      let solde_total = credit_total - debit_total;

      this.creditTotalTarget.textContent = this.make_financing_number(credit_total);
      this.debitTotalTarget.textContent = this.make_financing_number(debit_total);
      this.soldeTotalTarget.textContent = this.make_financing_number(solde_total);
      this.total_being_edited = false;
    });
  }

  make_financing_number(number) {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
}
