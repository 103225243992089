import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["stepContainer", "form", "hiddenCandidateRoleInput", "hiddenLandlordRoleInput"];
  static values = { url: String };

  currentStepNumber = 0;

  initialize() {
    this.onSubmit = this.onSubmit.bind(this)
    this.onRadioChange = this.onRadioChange.bind(this)
  }

  connect() {
    // Si erreurs, on affiche l'étape correspondante
    const errorGroup = this.element.querySelector(".oq-form-group.is-invalid");

    if (errorGroup) {
      const step = errorGroup.closest('[data-sign-up-target="stepContainer"]');
      if (step) this.currentStepNumber = Array.from(this.stepContainerTargets).indexOf(step);
      this.showStep(this.currentStepNumber);
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    this.cleanErrors();

    // Validation serveur
    const response = await this.validateForm();

    if (!response.ok) {
      throw new Error("Erreur dans la réponse du network");
    }

    const data = await response.json();
    if (data.result.status === 200) {
      this.transitionSteps();
    } else if (data.result.status === 422) {
      this.displayErrors(data.result);
    }
  }

  async validateForm() {
    const formData = new FormData(this.formTarget);

    const data = Object.fromEntries(
      [...formData.entries()]
        .filter(([key]) => key.startsWith("user["))
        .map(([key, value]) => {
          const newKey = key.match(/\[(.*)\]/)[1];
          return [newKey, value];
        })
    );

    let fields = [];

    switch (this.currentStepNumber) {
      case 0:
        fields = ["email", "user_type"];
        break;
      case 1:
        fields = ["first_name", "last_name", "optin_legal_mention", "phone"];
        break;
    }

    const fieldsToCheck = JSON.stringify(Object.fromEntries(Object.entries(data).filter(([key]) => fields.includes(key))));
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const response = await fetch(this.urlValue, {
      method: "POST",
      body: fieldsToCheck,
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      credentials: "same-origin",
    });

    return response;
  }

  transitionSteps() {
    const currentStep = this.stepContainerTargets[this.currentStepNumber];
    const nextStep = this.getNextStep();

    currentStep.classList.toggle("opacity-0");
    setTimeout(() => {
      currentStep.classList.toggle("hidden");
      nextStep.classList.toggle("hidden");
      nextStep.classList.toggle("opacity-0");
    }, 400);

    this.currentStepNumber++;
  }

  displayErrors(data) {
    Object.entries(data).forEach(([key, value]) => {
      if (value === "ok") return;

      const errorField = this.getErrorFieldByKey(key);
      if (errorField) errorField.textContent = value;
    });
  }

  cleanErrors() {
    const groups = this.element.querySelectorAll(`.oq-form-group`);
    if (groups) {
      groups.forEach((group) => {
        group.classList.remove("is-invalid");
        const field = group.querySelector(".field-error");
        if (field) field.textContent = "";
      });
    }
  }

  showStep(stepNumber) {
    this.stepContainerTargets.forEach((step, index) => {
      if (index === stepNumber) {
        step.classList.remove("hidden", "opacity-0");
      } else {
        step.classList.add("hidden", "opacity-0");
      }
    });
  }

  onRadioChange(e) {
    const value = e.currentTarget.value;

    if (value === "candidate") {
      this.hiddenCandidateRoleInputTarget.value = true;
      this.hiddenLandlordRoleInputTarget.value = false;
    } else if (value === "landlord") {
      this.hiddenLandlordRoleInputTarget.value = true;
      this.hiddenCandidateRoleInputTarget.value = false;
    }
  }

  getNextStep() {
    const items = this.stepContainerTargets;
    if (this.currentStepNumber < items.length - 1) {
      return items[this.currentStepNumber + 1];
    }
  }

  getErrorFieldByKey(key) {
    const group = this.element.querySelector(`.oq-form-group.${key}`);
    if (group) {
      group.classList.add("is-invalid");

      return group.querySelector(".field-error");
    }
  }
}
