import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["review", "reviewsWrapper"];

  connect() {
    this.currentIndex = 0;
    this.updateCarousel();
  }

  onNext() {
    this.currentIndex = this.currentIndex + 1 >= this.reviewTargets.length ? 0 : this.currentIndex + 1;
    this.updateCarousel();
  }

  onBack() {
    this.currentIndex = this.currentIndex - 1 < 0 ? this.reviewTargets.length - 1 : this.currentIndex - 1;
    this.updateCarousel();
  }

  updateCarousel() {
    const reviewsWrapper = this.reviewsWrapperTarget;

    // Calcule la distance de translation (la largeur d'une review)
    const translateXValue = -(this.currentIndex * 100); // On utilise toute la largeur pour chaque review

    reviewsWrapper.style.transform = `translateX(${translateXValue}%)`;
    reviewsWrapper.classList.add("transition-transform", "duration-500", "ease-in-out");
  }
}
